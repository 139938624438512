
.icon-documents:before {
  content: "\e943";
}
.icon-version:before {
  content: "\e92d";
}
.icon-subject:before {
  content: "\e951";
}
.icon-file-code:before {
  content: "\e921";
}
.icon-file-video:before {
  content: "\e922";
}
.icon-file-audio:before {
  content: "\e923";
}
.icon-file-zip:before {
  content: "\e924";
}
.icon-file-presentation:before {
  content: "\e925";
}
.icon-file-spreadsheet:before {
  content: "\e926";
}
.icon-file-document:before {
  content: "\e927";
}
.icon-file-pdf:before {
  content: "\e928";
}
.icon-file-text:before {
  content: "\e929";
}
.icon-file:before {
  content: "\e92a";
}
.icon-error-log:before {
  content: "\e919";
}
.icon-drive:before {
  content: "\e961";
}
.icon-title:before {
  content: "\e960";
}
.icon-training:before {
  content: "\e95f";
}
.icon-pending:before {
  content: "\e95e";
}
.icon-relation:before {
  content: "\e95d";
}
.icon-state:before {
  content: "\e95c";
}
.icon-subscription:before {
  content: "\e95b";
}
.icon-export:before {
  content: "\e958";
}
.icon-shift:before {
  content: "\e95a";
}
.icon-course:before {
  content: "\e959";
}
.icon-button:before {
  content: "\e957";
}
.icon-billboard:before {
  content: "\e956";
}
.icon-page:before {
  content: "\e955";
}
.icon-news:before {
  content: "\e954";
}
.icon-entry:before {
  content: "\e953";
}
.icon-wait:before {
  content: "\e952";
}
.icon-waitlist:before {
  content: "\e950";
}
.icon-member:before {
  content: "\e94f";
}
.icon-block-type:before {
  content: "\e94e";
}
.icon-reserve:before {
  content: "\e94d";
}
.icon-date:before {
  content: "\e94c";
}
.icon-today:before {
  content: "\e94b";
}
.icon-reserve-type:before {
  content: "\e94a";
}
.icon-court-block:before {
  content: "\e949";
}
.icon-court:before {
  content: "\e948";
}
.icon-nullify:before {
  content: "\e947";
}
.icon-calendar:before {
  content: "\e946";
}
.icon-expand:before {
  content: "\e945";
}
.icon-print:before {
  content: "\e944";
}
.icon-document:before {
  content: "\e942";
}
.icon-attachment:before {
  content: "\e940";
}
.icon-send:before {
  content: "\e941";
}
.icon-status:before {
  content: "\e93f";
}
.icon-ticket:before {
  content: "\e93e";
}
.icon-department:before {
  content: "\e93c";
}
.icon-area:before {
  content: "\e93d";
}
.icon-fee:before {
  content: "\e93b";
}
.icon-balance:before {
  content: "\e93a";
}
.icon-administration:before {
  content: "\e939";
}
.icon-holiday:before {
  content: "\e938";
}
.icon-copy:before {
  content: "\e937";
}
.icon-drag:before {
  content: "\e936";
}
.icon-schedule:before {
  content: "\e935";
}
.icon-social:before {
  content: "\e933";
}
.icon-category:before {
  content: "\e934";
}
.icon-contact:before {
  content: "\e932";
}
.icon-notification-type:before {
  content: "\e931";
}
.icon-activity:before {
  content: "\e92f";
}
.icon-service:before {
  content: "\e930";
}
.icon-province:before {
  content: "\e92e";
}
.icon-notification:before {
  content: "\e92c";
}
.icon-directory:before {
  content: "\e92b";
}
.icon-media:before {
  content: "\e91f";
}
.icon-admin:before {
  content: "\e920";
}
.icon-check:before {
  content: "\e91e";
}
.icon-checkbox:before {
  content: "\e91a";
}
.icon-checkedbox:before {
  content: "\e91b";
}
.icon-action-log:before {
  content: "\e91c";
}
.icon-provider-log:before {
  content: "\e91d";
}
.icon-search:before {
  content: "\e918";
}
.icon-down:before {
  content: "\e915";
}
.icon-up:before {
  content: "\e916";
}
.icon-more:before {
  content: "\e917";
}
.icon-last:before {
  content: "\e911";
}
.icon-first:before {
  content: "\e912";
}
.icon-next:before {
  content: "\e913";
}
.icon-closed:before {
  content: "\e913";
}
.icon-prev:before {
  content: "\e914";
}
.icon-delete:before {
  content: "\e90f";
}
.icon-edit:before {
  content: "\e910";
}
.icon-add:before {
  content: "\e90e";
}
.icon-create:before {
  content: "\e90e";
}
.icon-filter:before {
  content: "\e90d";
}
.icon-email-queue:before {
  content: "\e90c";
}
.icon-white-email:before {
  content: "\e90b";
}
.icon-menu:before {
  content: "\e90a";
}
.icon-login:before {
  content: "\e909";
}
.icon-back:before {
  content: "\e907";
}
.icon-settings:before {
  content: "\e908";
}
.icon-user:before {
  content: "\e906";
}
.icon-logout:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e904";
}
.icon-view:before {
  content: "\e900";
}
.icon-visible:before {
  content: "\e900";
}
.icon-hide:before {
  content: "\e901";
}
.icon-hidden:before {
  content: "\e901";
}
.icon-password:before {
  content: "\e902";
}
.icon-email:before {
  content: "\e903";
}
