:root {
    /* Colors */
    --primary-color             : #1d295f;
    --secondary-color           : #1a79a4;
    --accent-color              : #27a4ff;
    --alternative-color         : #0283c8;

    --content-color             : white;
    --subtitle-color            : rgb(94, 108, 132);
    --drop-color                : rgba(9, 30, 66, .5);
    --grid-color                : rgb(248, 248, 248);
    --box-shadow                : rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;

    --white-color               : white;
    --black-color               : rgb(34, 34, 34);
    --lightest-gray             : rgb(249, 250, 252);
    --lighter-gray              : rgb(244, 245, 247);
    --light-gray                : rgb(235, 236, 240);
    --dark-gray                 : rgb(218, 220, 224);
    --darker-gray               : rgb(204, 205, 207);
    --darkest-gray              : rgb(144, 145, 147);

    --font-size                 : 14px;
    --font-color                : var(--black-color);
    --font-light                : rgb(66, 82, 110);
    --font-lighter              : rgb(94, 108, 132);

    --error-color               : rgb(188, 28, 72);
    --error-text-color          : rgb(255, 0, 51);
    --success-color             : rgb(1, 163, 28);
    --warning-color             : rgb(240, 150, 9);
    --notify-color              : rgb(255, 0, 51);

    --border-width              : 1px;
    --border-radius             : 10px;
    --border-radius-small       : 6px;
    --border-color-dark         : rgb(26, 26, 35);
    --border-color-medium       : rgb(218, 220, 224);
    --border-color-light        : rgb(235, 236, 240);


    /* App Variables */
    --full-height               : 100dvh;
    --main-padding              : 24px;
    --main-gap                  : 12px;
    --top-height                : 0px;

    --topbar-height             : 56px;
    --topbar-background         : white;
    --topbar-border             : 1px solid var(--border-color-light);
    --topbar-logo-height        : 44px;
    --topbar-icon-size          : 32px;
    --topbar-icon-font          : 16px;
    --topbar-icon-color         : var(--title-color);
    --topbar-icon-background    : rgba(0, 0, 0, 0.1);
    --topbar-icon-hover-color   : var(--title-color);
    --topbar-icon-hover-bg      : rgba(0, 0, 0, 0.2);

    --sidebar-width             : 60px;
    --sidebar-space             : 60px;
    --sidebar-background        : var(--primary-color);
    --sidebar-icon-size         : 40px;
    --sidebar-icon-font         : 18px;
    --sidebar-icon-color        : white;
    --sidebar-icon-background   : var(--primary-color);
    --sidebar-icon-hover-color  : white;
    --sidebar-icon-hover-bg     : var(--accent-color);

    --navigation-width          : 240px;
    --navigation-space          : 240px;
    --navigation-background     : var(--lighter-gray);
    --navigation-border         : none;

    --header-height             : calc(32px + var(--main-padding));
    --header-padding            : calc(var(--main-padding) / 2) var(--main-padding);

    --main-height               : 100dvh;
    --main-margin               : 0px;
    --main-radius               : 0px;

    --title-color               : rgb(24, 45, 103);
    --title-font-weight         : 400;
    --title-font-size           : 22px;
    --title-letter-spacing      : 1px;

    --tabs-table                : calc(30px + var(--main-gap));
    --tabs-dialog               : 32px;
    --tabs-gap                  : 8px;

    --stats-height              : 56px;

    --filter-padding            : var(--main-gap);
    --filter-height             : calc(var(--input-height) + var(--filter-padding) * 2 + var(--main-gap));
    --filter-background         : var(--lighter-gray);
    --filter-right              : var(--main-gap);
    --filter-border             : none;

    --tooltip-color             : white;
    --tooltip-background        : var(--primary-color);

    --table-background          : var(--lighter-gray);
    --table-hover               : var(--lighter-gray);
    --table-selected            : var(--lighter-gray);
    --table-pages               : var(--lighter-gray);
    --table-border-outer        : 1px solid var(--border-color-light);
    --table-border-inner        : 1px solid var(--border-color-light);
    --table-radius-outer        : var(--border-radius);
    --table-radius-inner        : 0px;
    --table-radius-last         : var(--border-radius);
    --table-font-size           : 13px;
    --table-font-color          : var(--black-color);
    --table-title-weight        : 600;
    --table-overflow            : scroll;

    --none-background           : var(--lighter-gray);
    --dropzone-background       : var(--lighter-gray);

    --details-width             : 300px;
    --details-width-wide        : 360px;
    --details-width-large       : 440px;
    --details-gap               : 16px;
    --details-spacing-small     : 12px;
    --details-spacing-big       : 16px;
    --details-background        : var(--lightest-gray);
    --details-border-color      : var(--border-color-light);

    --dialog-header             : 64px;
    --dialog-footer             : 64px;
    --dialog-spacing            : 32px;
    --dialog-padding            : 24px;
    --dialog-radius             : calc(var(--border-radius) * 1.5);

    --input-height              : 48px;
    --input-font                : 13px;
    --input-icon                : 16px;
    --input-label               : 18px;
    --input-vert-padding        : 6px;
    --input-horiz-padding       : 12px;
    --input-padding             : var(--input-vert-padding) var(--input-horiz-padding);
    --input-label-color         : var(--darkest-gray);
    --input-label-focus         : var(--black-color);
    --input-disabled-color      : rgb(150, 150, 150);
    --input-border-color        : rgb(210, 211, 215);
    --input-border-disabled     : rgb(235, 236, 240);
    --input-border-hover        : rgb(26, 26, 35);
    --input-border-focus        : var(--black-color);
    --input-check-normal        : var(--black-color);
    --input-check-focus         : var(--border-color-dark);
    --input-check-checked       : var(--primary-color);
    --input-check-disabled      : var(--darkest-color);
    --input-radio-normal        : var(--primary-color);
    --input-radio-disabled      : var(--darkest-gray);
    --input-toggle-bg           : var(--primary-color);
    --input-toggle-normal       : #ccc;
    --input-toggle-checked      : #52cf71;
    --input-toggle-disabled     : var(--darkest-gray);

    --inputicon-height          : 48px;
    --inputicon-move            : 10px;
    --inputicon-font            : 16px;
    --inputicon-icon            : 20px;

    --media-main-color          : var(--primary-color);
    --media-border-color        : var(--border-color-light);
    --media-border-width        : var(--border-width);
    --media-name-color          : var(--lighter-gray);
    --media-actions-color       : var(--lighter-gray);
    --media-trans-light         : rgb(236, 237, 238);
    --media-trans-dark          : rgb(226, 227, 228);


    /* Z Values */
    --z-backdrop                : 3000;
    --z-details                 : 3001;
    --z-sidebar                 : 3002;
    --z-navigation              : 3003;
    --z-drawer                  : 3004;
    --z-dialog                  : 3005;
    --z-dropzone                : 3006;
    --z-menu                    : 3007;
    --z-preview                 : 3008;
    --z-result                  : 3009;
    --z-tooltip                 : 3010;


    /* Fonts */
    --main-font                 : "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    --title-font                : "Cooper", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#container {
    --calendar-height           : calc(var(--main-height) - var(--header-height) - var(--main-padding));

    --document-height           : calc(var(--main-height) - var(--header-height) - var(--main-padding));
    --document-gap              : var(--main-gap);
    --document-list-width       : 260px;
    --document-view-width       : calc(100vw - var(--sidebar-space) - var(--document-list-width) - 2 * var(--main-padding) - 4px - var(--document-gap));
}

@media (max-width: 1000px) {
    :root {
        --sidebar-width         : 160px;
        --sidebar-space         : 0px;
        --navigation-space      : 0px;
        --top-height            : var(--topbar-height);
    }
}

@media (max-width: 700px) {
    #container {
        --document-list-width   : 0px;
        --document-gap          : 0px;
    }
}

@media (max-width: 500px) {
    :root {
        --main-padding          : 12px;
    }
}
