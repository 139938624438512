/**
 * The Fonts Faces
 */
@font-face {
    font-family: "Lato";
    src: url("../Fonts/Lato/Lato-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Lato";
    src: url("../Fonts/Lato/Lato-Light.ttf");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Lato";
    src: url("../Fonts/Lato/Lato-Bold.ttf");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "Lato";
    src: url("../Fonts/Lato/Lato-Bold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../Fonts/Lato/Lato-Italic.ttf");
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: "Lato";
    src: url("../Fonts/Lato/Lato-LightItalic.ttf");
    font-weight: 200;
    font-style: italic;
}


@font-face {
    font-family: "Cooper";
    src: url("../Fonts/Cooper/Cooper-Light.ttf");
    font-weight: 200;
    font-style: normal;
}


@font-face {
    font-family: "icomoon";
    src:
        url("../Fonts/Icomoon/icomoon.ttf?9i6t86") format("truetype"),
        url("../Fonts/Icomoon/icomoon.woff?9i6t86") format("woff"),
        url("../Fonts/Icomoon/icomoon.svg?9i6t86#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}



/**
 * The Main Styles
 */
html, body, #root {
    min-height: var(--full-height);
    overscroll-behavior-y: none;
}
body {
    margin: 0;
    padding: 0;
    font-size: var(--font-size);
    color: var(--font-color);
}

.right-space.right-space {
    margin-right: 8px;
}
.right-space-big.right-space-big {
    margin-right: 16px;
}
.bottom-space.bottom-space {
    margin-bottom: 8px;
}
.top-space.top-space {
    margin-top: 8px;
}
.top-space-big.top-space-big {
    margin-top: 16px;
}
.top-space-huge.top-space-huge {
    margin-top: 32px;
}



/**
 * Results
 */
.text-green.text-green.text-green {
    color: var(--success-color);
}
.text-yellow.text-yellow.text-yellow {
    color: var(--warning-color);
}
.text-red.text-red.text-red {
    color: var(--error-color);
}



/**
 * The Scrollbars
 */
:not(.no-scrollbars)::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}
:not(.no-scrollbars):not(.light-scrollbars)::-webkit-scrollbar:vertical {
    border-left: 1px solid var(--border-color-light);
}
:not(.no-scrollbars):not(.light-scrollbars)::-webkit-scrollbar:horizontal {
    border-top: 1px solid var(--border-color-light);
}

.light-scrollbars::-webkit-scrollbar-thumb,
:not(.no-scrollbars)::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 999px;
    box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 0.1);
}
.light-scrollbars:focus::-webkit-scrollbar-thumb:vertical,
:not(.no-scrollbars)::-webkit-scrollbar-thumb:vertical {
    border-left-width: 3px;
    min-height: 20px;
}
.light-scrollbars:focus::-webkit-scrollbar-thumb:horizontal,
:not(.no-scrollbars)::-webkit-scrollbar-thumb:horizontal {
    border-top-width: 3px;
    min-width: 20px;
}



/**
 * The Font
 */
body,
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="time"],
select,
textarea {
    font-family: var(--main-font);
}
